<template>
  <div class="common-layout">
    <el-container style="width:100vw; height: 100vh">
      <el-aside width="auto">
        <sidebar></sidebar>
      </el-aside>
      <el-container style="width:100vw">
        <el-header class="navbarHeader">
            <!--            <el-col v-bind:class="{navStandard: backState, navDisabled : !backState}" @click="prevPage">-->
            <div class="left-header">
              <div v-bind:class="{navStandard: backState, navDisabled : !backState}" @click="prevPage">
                <span class="el-icon-arrow-left"></span>
                <span>Indietro</span>
              </div>
              <span style="margin: 0 5px"> | </span>
              <div v-bind:class="{navStandard: forwardState, navDisabled : !forwardState}" @click="nextPage">
                <span>Avanti</span>
                <span class="el-icon-arrow-right"></span>
              </div>
            </div>

            <login-dropdown></login-dropdown>

        </el-header>
        <el-main>
          <router-view></router-view>
        </el-main>
        <Footer></Footer>
<!--        <el-footer>-->
<!--          Sunny - Versione {{ getVersion() }}. L'aspetto del sito potrebbe subire variazioni nel corso degli-->
<!--          aggiornamenti-->
<!--        </el-footer>-->
      </el-container>
    </el-container>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar";
import {mapActions, mapGetters} from "vuex";
import UserMixin from "../mixins/UserMixin";
import Footer from "../components/Footer";
import LoginDropdown from "../components/LoginDropdown";

export default {
  name: 'dashboard',
  mixins: [UserMixin],
  components: {LoginDropdown, Footer, Sidebar},
  data() {
    return {
      imgSrc: "",
      headerName: "",
      forwardState: false,
      backState: false
    };
  },
  computed: {
    ...mapGetters(["sidebarIsCollapse", "appUser", "currentUser"]),
  },
  methods: {
    ...mapActions(["toggleSidebar", "fetchUser"]),
    goTo(name) {
      this.$router.push({name})
    },
    printRoute(route) {
      console.log(route)
    },
    prevPage() {
      if (history.state.back) {
        history.back();
      }
    },
    nextPage() {
      if (history.state.forward) {
        history.forward();
      }
    },
    sidebarIndexFix(){

    }
    //is-active
  },
  watch: {
    async $route() {
      this.forwardState = history.state.forward;
      this.backState = history.state.back;

      this.sidebarIndexFix()

    }
  },
  // async created() {
  //   await this.fetchUser(this.appUser.id);
  //   this.userName = this.getFullName(this.currentUser);
  //
  // },

}
</script>

<style scoped>

.el-footer {
  padding: 10px;
  height: auto;
  border-top: 1px solid #dedede
}

.el-header {
  border-bottom: 1px solid #dedede;
  padding: 10px;

}

.right-header {
  text-align: right;
}

.left-header {
  display: flex;
}

.el-main {
  width: 100%;
}

.navDisabled {
  color: #909399;
  cursor: default;
}

.navbarHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navStandard {
  color: black;
  cursor: pointer;
}

body > .el-container {
  margin-bottom: 40px;
}
</style>
