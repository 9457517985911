<template>
  <div class="wrapper sidebar-wrapper">
    <el-menu class="main-sidebar" default-active="0" :collapse="sidebarIsCollapse">
      <el-space :size="0">
        <el-button class="margin-1" icon="el-icon-s-unfold" circle v-if="sidebarIsCollapse"
                   @click="toggleSidebar"></el-button>
        <el-button class="margin-1" icon="el-icon-s-fold" circle v-else @click="toggleSidebar"></el-button>
        <div v-show="currentUser && !sidebarIsCollapse">
          <div class="name-container" v-if="!showCascader">
            <strong>{{ headerName }}</strong>

            <div class="icon-container">
              <el-avatar :src="imgSrc" shape="square" fit="contain"></el-avatar>
              <i class="el-icon-arrow-down el-icon--right pointer" @click="createCondominiaDataset"></i>
            </div>
          </div>

          <div v-else class="pr-2">
            <el-cascader
                filterable
                :filter-method="filterCascaderOptions"
                v-model="selectedCondo"
                :options="condominiumOptions"
                @change="changeAppCondominium"
                @visible-change="toggleCascader"
                :show-all-levels="false"
            >
            </el-cascader>
            <!-- <i class="el-icon-close" @click="closeCascader"></i> -->
          </div>

        </div>
      </el-space>

      <el-menu-item index="1" @click="goTo('dashboardIndex')">
        <i class="el-icon-s-home"></i>
        <span>Home</span>
      </el-menu-item>
      <el-menu-item index="2" @click="goTo('association')">
        <i class="el-icon-document-add"></i>
        <span>Associa Appartamento</span>
      </el-menu-item>
      <!-- <el-menu-item index="3" @click="$router.push('/dashboard/appartamento/'+getUserApartmentId())" v-if="currentUser && hasAps()">
        <i class="el-icon-school"></i>
        <span>Appartamento</span>
      </el-menu-item>
      <el-menu-item disabled index="4" v-else-if="currentUser && currentUser.type!=='admin'">
        <i class="el-icon-school"></i>
        <span>Appartamento</span>
      </el-menu-item> -->
      <!-- <el-menu-item ref="readingsButton" index="5" @click="$router.push('/dashboard/letture/'+getUserApartmentId())" v-if="currentUser && hasAps()">
        <i class="el-icon-data-line"></i>
        <span>Letture</span>
      </el-menu-item>
      <el-menu-item disabled index="6" v-else-if="currentUser && currentUser.type!=='admin'">
        <i class="el-icon-data-line"></i>
        <span>Letture</span>
      </el-menu-item> -->
      <el-menu-item index="7" @click="goTo('utenti')" v-if="isGranted('ROLE_MANAGER')">
        <i class="el-icon-user"></i>
        <span>Utenti</span>
      </el-menu-item>
      <el-menu-item index="8" @click="goTo('condomini')" v-if="hasCondo()">
        <i class="el-icon-office-building"></i>
        <span>Condomini</span>
      </el-menu-item>
      <el-menu-item index="9" @click="goTo('dataimport')" v-if="isGranted('ROLE_MANAGER')">
        <i class="el-icon-document-add"></i>
        <span>Importa Letture</span>
      </el-menu-item>
      <el-menu-item index="10" @click="goTo('condominiumimport')" v-if="isGranted('ROLE_MANAGER')">
        <i class="el-icon-office-building"></i>
        <span>Importa Condominio</span>
      </el-menu-item>

    </el-menu>

    <div v-if="!sidebarIsCollapse" class="sidebar-footer">
      <div class="owner-info" v-if="!sidebarIsCollapse">
        <el-avatar style="margin-bottom: 30px;" :src="owner.imgSrc" :size="100" shape="square"
                   fit="contain"></el-avatar>
        <h3 class="no-margin"> {{ owner.name }} </h3>
        <p class="no-margin sidebar-owner-info"> {{ owner.address }} </p>
        <p class="no-margin sidebar-owner-info"> {{ owner.city }} </p>
        <p class="no-margin sidebar-owner-info"> {{ owner.tel }} </p>
        <p class="no-margin sidebar-owner-info"> {{ owner.tel2 }} </p>
      </div>
    </div>

  </div>


</template>

<script>
import {mapActions, mapGetters} from "vuex";
import UserMixin from "../mixins/UserMixin";

export default ({
  name: 'dashboard',
  mixins: [UserMixin],
  data() {
    return {
      imgSrc: "",
      headerName: "",
      owner: {
        imgSrc: "",
        name: "",
        address: "",
        tel: "",
        tel2: ""
      },
      showCascader: false,
      condominiumOptions: null,
      selectedCondo: null
    };
  },
  computed: {
    ...mapGetters(["sidebarIsCollapse", "userFullname", "userRoles", "appUser", "currentUser", "isGranted", "appCondominium", "appCondominiumList"])
  },

  methods: {
    ...mapActions(["toggleSidebar", "fetchUser", "fetchAppCondominiumList", "fetchAppCondominium"]),
    goTo(name) {
      if (name !== this.$route.name) {
        this.$router.push({name})
      }
    },

    hasCondo() {
      return this.isGranted("ROLE_MANAGER") || (this.currentUser && this.currentUser.type === 'admin');
    },
    hasAps() {
      return this.currentUser.apartments.length
    },
    getUserApartmentId() {
      return this.currentUser.apartments[0].id;
    },
    setOwnerInfo() {
      if (this.appCondominium) {
        let imgSource = this.appCondominium.imgSource
        if (imgSource === 'default') {
          imgSource = 'owner'
        }

        const user = this.appCondominium[imgSource];

        this.imgSrc = user.imgData
        if (this.appCondominium.descriptionShort && this.appCondominium.descriptionShort !== "") {
          this.headerName = this.appCondominium.descriptionShort
        } else {
          this.headerName = this.appCondominium.description
        }


        this.owner.name = user.businessName
        this.owner.imgSrc = user.imgData
        this.owner.address = user.address.street + ', ' + user.address.number
        this.owner.city = user.address.city + ' (' + user.address.state + ')'
        this.owner.tel = user.phoneNumber1
        this.owner.tel2 = user.phoneNumber2
      } else {
        this.imgSrc = "logo_default_sunny.PNG";
        this.headerName = "Sunny";

        this.owner.name = "ESD Servizi Globali"
        this.owner.imgSrc = "logo_default_sunny.PNG"
        this.owner.address = "Via C. E. Filiberto, 1"
        this.owner.city = "San Giusto Canavese (TO)"
        this.owner.tel = "+39 3204058840"
      }


    },
    async createCondominiaDataset() {

      if (!this.currentUser || this.currentUser.id != this.appUser.id) {
        await this.fetchUser(this.appUser.id)
      }

      this.showCascader = true;

      //fetch data
      if (!this.appCondominiumList) {
        await this.fetchAppCondominiumList();
      }


      this.condominiumOptions = []

      if (this.currentUser.roles.find(el => el === "ROLE_ADMIN") ||
          this.currentUser.roles.find(el => el === "ROLE_MANAGER")) {

        this.condominiumOptions.push({
          value: 'all',
          label: 'Tutti',
          children: []
        })

        this.appCondominiumList.all.forEach(condo => {
          this.condominiumOptions[0].children.push({
            value: condo.id,
            label: condo.description || "Nominativo condominio assente"
          })
        });

      }

      if (this.currentUser.type === "admin") {
        //

        let index = this.condominiumOptions.push({
          value: 'owner',
          label: 'Cliente Sunny',
          children: []
        })
        index--;
        this.appCondominiumList["owner"].forEach(condo => {
          this.condominiumOptions[index].children.push({
            value: condo.id,
            label: condo.description || "Nominativo condominio assente"
          })
        });

        index = this.condominiumOptions.push({
          value: 'admin',
          label: 'Amministratore Condominiale',
          children: []
        })
        index--;
        this.appCondominiumList["admin"].forEach(condo => {
          this.condominiumOptions[index].children.push({
            value: condo.id,
            label: condo.description || "Nominativo condominio assente"
          })
        });

        index = this.condominiumOptions.push({
          value: 'supplier',
          label: 'Gestore calore/acqua',
          children: []
        })
        index--;
        this.appCondominiumList["supplier"].forEach(condo => {
          this.condominiumOptions[index].children.push({
            value: condo.id,
            label: condo.description || "Nominativo condominio assente"
          })
        });

        index = this.condominiumOptions.push({
          value: 'technician',
          label: 'Assistenza Tecnica',
          children: []
        })
        index--;
        this.appCondominiumList["technician"].forEach(condo => {
          this.condominiumOptions[index].children.push({
            value: condo.id,
            label: condo.description || "Nominativo condominio assente"
          })
        });


      }

      //lista appartamenti di cui si è proprietari/affittuari ecc

      let index = this.condominiumOptions.push({
        value: 'apartments',
        label: 'Appartamenti',
        children: []
      })
      index--;
      this.appCondominiumList["apartments"].forEach(apartment => {
        //se il condominio è stato già inserito nell'elenco si aggiunge solo l'appartamento 
        let condoIndex = this.condominiumOptions[index].children.findIndex(el => {
          return apartment.condominium.id === el.value
        })

        if (condoIndex != -1) {
          this.condominiumOptions[index].children[condoIndex].children.push({
            value: apartment.id,
            label: "Piano: " + apartment.floor + " - Numero: " + apartment.number
          })
        } else {
          condoIndex = this.condominiumOptions[index].children.push({
            value: apartment.condominium.id,
            label: apartment.condominium.description || "Nominativo condominio assente",
            children: []
          }) - 1;
          this.condominiumOptions[index].children[condoIndex].children.push({
            value: apartment.id,
            label: "Piano: " + apartment.floor + " - Numero: " + apartment.number
          })
        }


      });

    },
    async changeAppCondominium() {

      await this.fetchAppCondominium(this.selectedCondo[1])

      this.setOwnerInfo()
      this.showCascader = false
      if (this.selectedCondo[0] === "apartments") {
        const idApartment = this.selectedCondo[2];
        await this.$router.push('/dashboard/appartamento/' + idApartment)
      } else {
        await this.$router.push('/dashboard/condominio/' + this.selectedCondo[1])
      }

    },
    toggleCascader(open) {
      if (!open) {
        this.showCascader = false
      }
    },

  },

  async created() {
    await this.fetchUser(this.appUser.id);
    this.setOwnerInfo();
    this.emitter.on('update-cond', (evt) => {
      this.headerName = evt.condDescription;
    })
  },
  closeCascader() {
    this.showCascader = false
  },
  filterCascaderOptions(node, keyword) {
    return node.toLower().search(keyword.toLower())
  }
})
</script>

<style scoped>

.wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid #e6e6e6;
}

.main-sidebar:not(.el-menu--collapse) {
  width: 300px;
}


.margin-1 {
  margin: 10px;
}

.username {
  margin: 0
}

.name-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 225px;
}

.sidebar-footer {
  padding: 10px;
}

.owner-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
}

.no-margin {
  margin: 0;
}

.sidebar-owner-info {
  font-size: 15px;
}

.pr-2 {
  padding-right: 1em;
}

.icon-container {
  display: flex;
  align-items: center;
}

</style>
